import axios from 'axios'
import router from '../router/index'
import md5 from 'js-md5'
import { Message } from 'element-ui'

axios.defaults.withCredentials = true
axios.defaults.timeout = 30000
axios.withCredentials = true

/* 本地 */
// axios.defaults.baseURL = 'http://192.168.1.200:2000'
/* 测试环境 */
// axios.defaults.baseURL = 'https://test.yuchen2022.com:8700'
/* 正式环境 */
axios.defaults.baseURL = 'https://os.yuchen2022.com:8701'

// 请求拦截
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem('token')
  config.headers.loginway = 'pc'
  config.headers.sign = getPwdStr(config.data)
  if (window.localStorage.getItem('user') !== null && window.localStorage.getItem('user') !== '') {
    config.headers.Anotherplace = JSON.parse(window.localStorage.getItem('user')).anotherplace
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(function (response) {
  if (response.data.status === '3') {
    Message({ message: response.data.message, type: 'error' })
    setTimeout(() => {
      router.replace({ name: 'Login' })
    }, 1000)
  }
  if (response.data.status === '2') {
    Message({ message: response.data.message, type: 'error' })
    setTimeout(() => {
      router.replace({ name: 'Login' })
    }, 1000)
  }
  return response
}, function (error) {
  console.log(error)
  // errorHandle(error)
  if (error.response) {
    Message.error('服务器繁忙！')
    // if (error.response.status === 403) {
    //   Message.error('服务器繁忙')
    // } else if (error.response.status === 404) {
    //   Message.error('请求错误')
    // } else if (error.response.status === 500) {
    //   Message.error('服务器错误')
    // } else if (error.response.status >= 500) {
    //   Message.error('服务器异常')
    // }
  }
  return Promise.reject(error)
})

// get请求
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
      // Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
    })
  })
}

// post请求
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, getKey(params))
      .then(res => {
        resolve(res.data)
      // }).catch(errorHandle)
      }).catch(err => {
        reject(err)
        // Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
      })
  })
}

// put请求
export function put (url, params) {
  return new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
        // Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
      })
  })
}

// delete 请求
export function deletefn (url, params) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      data: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
      // Message({ message: '服务器繁忙！请稍后重试', type: 'error' })
    })
  })
}

export function getKey (param) {
  // console.log(param)
  let _data = {}
  let str = ''
  // _data = sort_ASCII(param)
  if (param === undefined) {
    str += 'key=zkpfw*%$YCLS@sdko34@%'
  } else {
    _data = sortASCII(param)
    for (let i in _data) { /* 1、判断value不为空，用&拼起来 */
      if (_data[i] !== '') {
        str += i + '=' + _data[i] + '&'
      }
    }
    str += 'key=zkpfw*%$YCLS@sdko34@%'
  }
  // _data['pwdKey'] = md5(str).toUpperCase()
  /* 2、拼接key */
  return _data
}
export function getPwdStr (data) {
  const ran = Math.floor(Math.random() * 99998) + 1
  const ti = new Date().getTime()
  data.ts = ti + ran
  data = sortASCII(data)
  // console.log(data)
  let str = ''
  if (data === {}) {
    str += 'key=zkpfw*%$YCLS@sdko34@%'
  } else { /* 1、判断value不为空，用&拼起来 */
    // for(let i in data){
    //   if (data[i] !== "" && data[i] !== false && data[i] !== null){
    //     str += i + "=" + data[i] + "&"
    //   }
    // }
    for (let i in data) {
      if (data[i] !== '' && data[i] !== null) {
        if (typeof data[i] === 'string') {
          str += i + '=' + data[i] + '&'
        } else if (typeof data[i] === 'number' && data[i] !== 0) {
          str += i + '=' + String(data[i]) + '&'
        } else if (typeof data[i] === 'object' && data[i].length !== 0) {
          str += i + '=' + JSON.stringify((data[i])) + '&'
        } else if (typeof data[i] === 'boolean' && data[i] === true) {
          str += i + '=true' + '&'
        }
      }
    }
    str += 'key=zkpfw*%$YCLS@sdko34@%'
  }
  // console.log(str)
  return md5(str).toUpperCase()
}
/* 对象排序 */
export function sortASCII (obj) {
  var arr = new Array()
  var num = 0
  for (var i in obj) {
    arr[num] = i
    num++
  }
  var sortArr = arr.sort()
  var sortObj = {}
  for (var i in sortArr) {
    sortObj[sortArr[i]] = obj[sortArr[i]]
  }
  return sortObj
}
